import { toast } from "react-toastify"

export const handleError = (error) => {
    switch (error.code) {
        case 'auth/invalid-email':
            return toast.error('Please provide a valid email address');
        case 'auth/wrong-password':
            return toast.error('Password incorrect');
        case 'auth/user-not-found':
            return toast.error('User with this email does not exist');
        case 'permission-denied':
            window.location = '/login';
            break;
        default:
            return toast.error(error.message);
    }
}

export const moneyFormat = (val) => {
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR'
    });
    return formatter.format(val).replace(',', '.');
}

export const getInvoiceNumber = () => {
    return Math.floor(Math.random() * (10000 - 1000) + 1000);
}