import Layout from 'components/containers/layout';
import './reviews.scss';

const Reviews = () => {
    return (
        <Layout title="Reviews">

        </Layout>
    )
}

export default Reviews;