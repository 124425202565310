import './modal.scss';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Modal = ({ className, title, open, setOpen, children }) => {
    const close = () => {
        setOpen(false);
    }

    const onClick = e => {
        e.stopPropagation();
    }

    return (
        <div className={`modal${className ? ` ${className}` : ''}${open ? ' open' : ''}`} onClick={close}>
            <div className="modal-content" onClick={onClick}>
                <CloseOutlinedIcon onClick={close} />
                <h2>{title}</h2>
                {children}
            </div>
        </div>
    )
}

export default Modal;