import './image-modal.scss';

import Modal from 'components/modal';
import { useCallback, useEffect, useState } from 'react';
import { getCategoryFolders, updateCategory } from 'firestore/categories';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { PulseLoader } from 'react-spinners';

const ImageModal = ({ category, open, setOpen, onUpdate }) => {
    const [selectedFolder, setSelectedFolder] = useState();
    const [folders, setFolders] = useState([]);
    const [images, setImages] = useState([]);

    const fetchCategoryFolders = useCallback(async () => {
        if (category) {
            const data = await getCategoryFolders(category?.id);
            setFolders(data);
        }
    }, [category])

    const selectImage = async (url) => {
        await updateCategory({
            ...category,
            image_url: url
        })
        setSelectedFolder(null);
        setFolders([]);
        setImages([]);
        setOpen(false);
        onUpdate({
            ...category,
            image_url: url
        });
    }

    const handleSetOpen = (val) => {
        setOpen(val);
        if (!val) {
            setSelectedFolder(null);
            setFolders([]);
            setImages([]);   
        }
    }

    useEffect(() => {
        fetchCategoryFolders();
    }, [fetchCategoryFolders])

    useEffect(() => {
        setImages(selectedFolder?.images || []);
    }, [selectedFolder])

    return (
        <Modal title="Image Select" className="image-modal" open={open} setOpen={handleSetOpen}>
            <p>Please select an image to set as the background</p>
            {!selectedFolder ? (
                <ul className="folders">
                    {folders.length === 0 ? (
                        <li><i>No folders found</i></li>
                    ) : (
                        folders.map((folder, i) => (
                            <li tabIndex={-1} key={i} onClick={() => setSelectedFolder(folder)}><FolderOutlinedIcon />{folder.name} <PulseLoader className="loader" size={6} /></li>
                        ))
                    )}
                </ul>
            ) : (
                <ul className="images">
                    {images.map((image, i) => (
                        <li tabIndex={-1} key={i} style={{ backgroundImage: `url(${image})`}} onClick={() => selectImage(image)} />
                    ))}
                </ul>
            )}
        </Modal>
    )
}

export default ImageModal;