import Layout from 'components/containers/layout';
import './products.scss';

const Products = () => {
    return (
        <Layout title="Products">

        </Layout>
    )
}

export default Products;