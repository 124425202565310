import './layout.scss';

import Body from '../body';
import Sidenav from '../sidenav';

const Layout = ({ title, children }) => {
    return (
        <div className="layout">
            <Sidenav />
            <Body title={title}>
                {children}
            </Body>
        </div>
    )
}

export default Layout;