import './table.scss';

import { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { moneyFormat } from 'utils';

const Table = ({ products, selectedProducts = [], removeProduct, selectRef, quantityRef, discountRef, addNewProduct }) => {
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setTotal(selectedProducts.map(x => x.total).reduce((a, b) => a + b, 0));
    }, [selectedProducts])

    return (
        <div className="new-invoice-table-container">
            <table className="new-invoice-table">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Discount (%)</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedProducts.map((product, i) => (
                        <tr key={i}>
                            <td>{product.name}</td>
                            <td>{product.quantity}</td>
                            <td>{product.discount}</td>
                            <td>{moneyFormat(product.price)}</td>
                            <td>{moneyFormat(product.total)} <CloseOutlinedIcon onClick={() => removeProduct(i)} /></td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <select ref={selectRef} defaultValue="">
                                <option disabled value="">Select</option>
                                {products.map((product, i) => (
                                    <option value={product.name} key={i}>{product.name}</option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <input type="number" ref={quantityRef} defaultValue={1} />
                        </td>
                        <td>
                            <input type="number" ref={discountRef} />
                        </td>
                        <td></td>
                        <td>
                            <button onClick={addNewProduct}>Add</button>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}>Subtotal</td>
                        <td>{moneyFormat(total)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Table;