import { addDoc, collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore/lite";
import { db } from "firestore";
import { handleError } from "utils";

export const getCategories = async () => {
    try {
        const col = collection(db, 'categories');
        const snapshot = await getDocs(col);
        const response = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}));
        return response;
    } catch (error) {
        handleError(error);
    }
}

export const addCategory = async (category) => {
    try {
        const col = collection(db, 'categories');
        const ref = await addDoc(col, category);
        return ref.id;
    } catch (error) {
        handleError(error);
    }
}

export const deleteCategory = async (id) => {
    try {
        const ref = doc(db, `category/${id}`);
        await deleteDoc(ref);
    } catch (error) {
        handleError(error);
    }
}

export const updateCategory = async (category) => {
    const ref = doc(db, `categories/${category.id}`);
    const { id, ...newCategory } = category;
    await setDoc(ref, newCategory);
}

export const getCategoryFolders = async (id) => {
    try {
        const col = collection(db, `categories/${id}/folders`);
        const snapshot = await getDocs(col);
        const response = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}));
        return response;
    } catch (error) {
        handleError(error);
    }
}