import './new-invoice-modal.scss';

import Modal from 'components/modal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getInvoiceNumber, handleError } from 'utils/utils';
import { toast } from 'react-toastify';
import { usePDF } from '@react-pdf/renderer';
import Invoice from 'components/pdf-templates';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from 'firestore';
import moment from 'moment';
import { Table } from './table';
import { checkInvoiceNumber, uploadInvoice } from 'firestore/invoices';

const NewInvoiceModal = ({ open, setOpen }) => {
    const selectRef = useRef();
    const quantityRef = useRef();
    const discountRef = useRef();
    const [recipient, setRecipient] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState(0);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [pdf, updatePdf] = usePDF({ document: <Invoice products={selectedProducts} recipient={recipient} invoiceNumber={invoiceNumber} /> });
    const products = useMemo(() => [
        {
            name: "Portrait",
            price: 700
        },
        {
            name: "Couple",
            price: 800
        },
        {
            name: "Family",
            price: 900
        }
    ], [])

    const addNewProduct = useCallback(() => {
        const product = products.find(x => x.name === selectRef.current.value); 
        setSelectedProducts(prev => [
            ...prev,
            {
                name: selectRef.current.value,
                quantity: quantityRef.current.value,
                discount: discountRef.current.value,
                price: product.price,
                total: product.price - (product.price * (discountRef.current.value / 100))
            }
        ])
    }, [products])

    const removeProduct = useCallback((index) => {
        const newArray = [...selectedProducts];
        newArray.splice(index, 1);
        setSelectedProducts(newArray);
    }, [selectedProducts])

    const generateInvoice = useCallback(() => {
        if (selectedProducts.length === 0) {
            toast.warning('Please add at least 1 product');
            return;
        } else {
            const storageRef = ref(storage, `/invoices/${recipient}-${moment().format("DD-MM-YYYY")}.pdf`);
            const uploadTask = uploadBytesResumable(storageRef, pdf.blob);
            uploadTask.on(
                "state_changed", (snapshot) => {
                    // const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                }, (err) => {
                    handleError(err);
                }, () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        window.open(url, "_blank");
                        uploadInvoice({
                            invoice_number: invoiceNumber,
                            products: selectedProducts,
                            recipient: recipient,
                            url: url,
                            date: moment().toString()
                        });
                    });
                }
            ); 
        }
    }, [pdf, recipient, selectedProducts, invoiceNumber])

    const recipientInput = useMemo(() => (
        <input 
            className="txt-recipient" 
            type="text" 
            placeholder="Recipient" 
            onChange={e => setRecipient(e.currentTarget.value)} 
        />
    ), [])

    const table = useMemo(() => (
        <Table 
            products={products} 
            selectedProducts={selectedProducts} 
            addNewProduct={addNewProduct} 
            discountRef={discountRef} 
            quantityRef={quantityRef} 
            removeProduct={removeProduct} 
            selectRef={selectRef} 
        />
    ), [addNewProduct, products, removeProduct, selectedProducts])

    const btnGenerateInvoice = useMemo(() => (
        <button 
            className="btn-generate-invoice" 
            onClick={generateInvoice}
        >
            Generate Invoice
        </button>
    ), [generateInvoice])

    const generateInvoiceNumber = useCallback(async () => {
        const num = getInvoiceNumber();
        const exists = await checkInvoiceNumber(num);
        if (exists) {
            generateInvoiceNumber();
        } else {
            setInvoiceNumber(num);
        }
        return exists;
    }, [])

    useEffect(() => {
        generateInvoiceNumber();
    }, [generateInvoiceNumber])

    useEffect(() => {
        updatePdf();
    }, [selectedProducts, updatePdf])

    return (
        <Modal className="new-invoice-modal" title="New Invoice" open={open} setOpen={setOpen}>
            <p>Please select all the products to be included in the invoice.</p>
            {recipientInput}
            {table}
            {btnGenerateInvoice}
        </Modal>
    )
}

export default NewInvoiceModal;