import './login.scss';
import Input from 'components/form/input/input';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Button from 'components/form/button';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'firestore';
import { handleError } from 'utils';
import { toast } from 'react-toastify';

const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const login = async () => {
        if (!loading) {
            if (!email.length) {
                toast.warning('Please enter an email');
                return;
            } else if (!password.length) {
                toast.warning('Please enter a password');
                return;
            } else {
                setLoading(true);
                try {
                    await signInWithEmailAndPassword(auth, email, password);
                    navigate('/dashboard', { replace: true });
                } catch (error) {
                    handleError(error);
                }
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        localStorage.clear();
    }, [])

    return (
        <div className="login">
            <div className="form">
                <img src={`${process.env.PUBLIC_URL}/img/logo-black.png`} alt="" />
                <h1>Login</h1>
                <p>Please enter your login details below</p>
                <Input 
                    type="email" 
                    placeholder="Email" 
                    icon={<EmailOutlinedIcon />} 
                    onChange={setEmail}
                />
                <Input 
                    type="password" 
                    placeholder="Password" 
                    icon={<LockOutlinedIcon />} 
                    onChange={setPassword} 
                />
                <Link to="/forgot-password">Forgot Password</Link>
                <Button onClick={login} loading={loading}>Login</Button>
            </div>
        </div>
    )
}

export default Login;