import { PulseLoader } from 'react-spinners';
import './button.scss';

const Button = ({ onClick, className, loading, title = '', children }) => {
    return (
        <button 
            type="button" 
            className={`${className ? className : ''}${loading ? ' loading' : ''}`} 
            onClick={onClick} 
            title={title}
        >
            {loading ? <PulseLoader size={8} color='#FFF' /> : <>{children}</>}
        </button>
    )
}

export default Button;