import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment/moment';
import { moneyFormat } from 'utils/utils';

const Invoice = ({ products = [], recipient = "", invoiceNumber = 0 }) => {
    const showDiscount = products.some(x => x.discount);
    const total = products.map(x => x.total).reduce((a, b) => a + b, 0);

    const style = {
        text: {
            fontSize: '12px',
            margin: '2px 0'
        },
        thead: {
            fontSize: '10px',
            letterSpacing: '2px',
            fontWeight: 'bold'
        }
    }
    return (
        <Document>
            <Page style={{ position: 'relative' }}>
                <View style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flex: '0 1 25%', height: '3px', backgroundColor: '#333' }} />
                    <Image src={`${process.env.PUBLIC_URL}/img/logo-black.png`} style={{ display: 'block', flex: '1 0 40%', margin: '0 auto 0 15px', backgroundColor: '#FFF' }} />
                    <View style={{ flex: '0 1 25%', height: '3px', backgroundColor: '#333' }} />
                </View>
                <View style={{ textAlign: 'right', marginRight: '20px' }}>
                    <Text style={style.text}>MinnieMedia</Text>
                    <Text style={style.text}>Bloubergstrand, Cape Town</Text>
                    <Text style={style.text}>078 785 2653</Text>
                    <Text style={style.text}>ashleigh@minniemedia.co.za</Text>
                    <Text style={style.text}>www.minniemedia.co.za</Text>
                </View>
                <View style={{ marginLeft: '20px', marginTop: '20px' }}>
                    <Text style={style.text}>{recipient}</Text>
                    <Text style={{ letterSpacing: '3px', fontSize: '20px', margin: '15px 0 5px' }}>INVOICE <Text style={{ marginLeft: '10px' }}>#{invoiceNumber}</Text></Text>
                    <Text style={style.text}>{moment().format("DD MMMM YYYY")}</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', margin: '20px 20px 10px', borderBottom: '2px solid #333', padding: '5px' }}>
                    <Text style={{ flex: '0 1 40%', ...style.thead }}>DESCRIPTION</Text>
                    <Text style={{ flex: '0 0 15%', textAlign: 'center', ...style.thead }}>QTY</Text>
                    <Text style={{ flex: '0 0 15%', ...style.thead}}>PRICE</Text>
                    {showDiscount && <Text style={{ flex: '0 0 15%', ...style.thead}}>DISCOUNT</Text>}
                    <Text style={{ flex: '0 0 15%', ...style.thead}}>TOTAL</Text>
                </View>
                {products.map((product, i) => (
                    <View key={i} style={{ display: 'flex', flexDirection: 'row', margin: '0px 20px', padding: '5px' }}>
                        <Text style={{ flex: '1 0 40%', ...style.text }}>{product.name}</Text>
                        <Text style={{ flex: '0 0 15%', textAlign: 'center', ...style.text }}>{product.quantity}</Text>
                        <Text style={{ flex: '0 0 15%', ...style.text}}>{moneyFormat(product.price)}</Text>
                        {showDiscount && <Text style={{ flex: '0 0 15%', ...style.text}}>({moneyFormat(product.price * (product.discount / 100))})</Text>}
                        <Text style={{ flex: '0 0 15%', ...style.text}}>{moneyFormat(product.total)}</Text>
                    </View>
                ))}
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '20px 25px 0px', borderTop: '2px solid #333', padding: '10px 0px' }}>
                    <Text style={{ width: '15%', fontSize: '12px' }}>TOTAL</Text>
                    <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '12px' }}>{moneyFormat(total)}</Text>
                </View>
                <View style={{ position: 'absolute', bottom: '30px', left: '20px', width: '100%' }}>
                    <Text style={{ fontSize: '10px', letterSpacing: '2px' }}>PAYMENT TERMS</Text>
                    <Text style={{ fontSize: '10px', marginTop: '10px' }}>Payment will need to be received 2 weeks prior to shoot taking place in order to cover travel and preparation costs.</Text>
                    <Text style={{ fontSize: '10px', marginTop: '20px', letterSpacing: '2px' }}>PAYMENT INFORMATION</Text>
                    <Text style={{ fontSize: '10px', marginTop: '10px' }}>First National Bank</Text>
                    <Text style={{ fontSize: '10px', marginTop: '5px' }}>Branch Code: 25656</Text>
                    <Text style={{ fontSize: '10px', marginTop: '5px' }}>Account Number: 12345678</Text>
                    <Text style={{ fontSize: '10px', marginTop: '5px' }}>Account Type: Cheque Account</Text>
                </View>
            </Page>
        </Document>
    )
}

export default Invoice;