import './dashboard.scss';

import Layout from 'components/containers/layout';

const Dashboard = () => {
    return (
        <Layout title="Dashboard">
            
        </Layout>
    )
}

export default Dashboard;