import './body.scss';
import Header from './header';

const Body = ({ title, children }) => {
    return (
        <div className={`body`}>
            <Header title={title} />
            {children}
        </div>
    )
}

export default Body;