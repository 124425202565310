import './sidenav.scss';

import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';

import Button from 'components/form/button';
import { signOut } from 'firebase/auth';
import { auth } from 'firestore';

const Sidenav = () => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(localStorage.sidenav_open === 'true' ? true : false);
    const [theme, setTheme] = useState(localStorage.theme || "light");
    const [settingsOpen, setSettingsOpen] = useState(localStorage.settings_open === 'true' ? true : false);

    const toggle = useCallback(() => {
        settingsOpen ? setTimeout(() => setOpen(false), 100) : setOpen(!open);
        setSettingsOpen(false);
    }, [settingsOpen, open])

    const toggleSettings = useCallback(() => {
        open ? setSettingsOpen(!settingsOpen) : setTimeout(() => setSettingsOpen(!settingsOpen), 50);
        setOpen(true);
    }, [open, settingsOpen])

    const toggleTheme = () => {
        if (theme === "light") {
            setTheme("dark");
        } else {
            setTheme("light")
        }
    }

    const logout = () => {
        toggle();
        signOut(auth);
        navigate('/login');
    }

    useEffect(() => {
        localStorage.setItem('sidenav_open', open);
    }, [open])

    useEffect(() => {
        localStorage.setItem('settings_open', settingsOpen);
    }, [settingsOpen])

    useEffect(() => {
        if (theme === "light") {
            localStorage.setItem("theme", "light");
            document.documentElement.style.setProperty('--background-color', '#EEE');
            document.documentElement.style.setProperty('--background-color-darker', '#FFF');
            document.documentElement.style.setProperty('--color', '#333');
        } else {
            localStorage.setItem("theme", "dark");
            document.documentElement.style.setProperty('--background-color', '#333');
            document.documentElement.style.setProperty('--background-color-darker', '#222');
            document.documentElement.style.setProperty('--color', '#FFF');
        }
    }, [theme])

    useEffect(() => {
        const width = window.innerWidth;
        if (width <= 768) {
            setOpen(false);
        }
    }, [])

    return (
        <div className={`sidenav${open ? ' open' : ''}`}>
            <div className="top" onClick={toggle}>
                <MenuOutlinedIcon />
                <img src={`${process.env.PUBLIC_URL}/img/logo-${theme === "light" ? 'black' : 'white'}.png`} alt="" />
            </div>
            <div className="sidenav-items">
                <Link to="/dashboard" className="sidenav-item">
                    <SpeedOutlinedIcon />
                    <p>Dashboard</p>
                </Link>
                <Link to="/images" className="sidenav-item">
                    <PermMediaOutlinedIcon />
                    <p>Images</p>
                </Link>
                <Link to="/products" className="sidenav-item">
                    <Inventory2OutlinedIcon />
                    <p>Products</p>
                </Link>
                <Link to="/invoices" className="sidenav-item">
                    <DescriptionOutlinedIcon />
                    <p>Invoices</p>
                </Link>
                <Link to="/reviews" className="sidenav-item">
                    <CommentOutlinedIcon />
                    <p>Reviews</p>
                </Link>
            </div>
            <div className={`settings${settingsOpen ? ' open' : ''}`}>
                <div className="btn-settings" onClick={toggleSettings}>
                    <SettingsOutlinedIcon />
                    <p>Settings</p>
                </div>
                <div className="settings-menu">
                    <div className="settings-item" onClick={toggleTheme}>
                        <p>Theme</p>
                    </div>
                    <Button title='logout' onClick={logout}>Logout</Button>
                </div>
            </div>
        </div>
    )
}

export default Sidenav;