import './categories.scss';

import Layout from 'components/containers/layout';
import { getCategories, updateCategory, addCategory } from 'firestore/categories';
import { useCallback, useEffect, useRef, useState } from 'react';

import Modal from 'components/modal';
import Input from 'components/form/input';
import Button from 'components/form/button';
import { toast } from 'react-toastify';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import ImageModal from './image-modal';
import Category from './category';

const Categories = () => {
    const [newCategoryOpen, setNewCategoryOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [imageModalCategory, setImageModalCategory] = useState();

    const newCategory = useRef('');
    
    const onDragEnd = useCallback((sourceId, sourceIndex, targetIndex) => {
        let nextState = swap(categories, sourceIndex, targetIndex);
        nextState.forEach((x, i) => {
            if (x.position !== i) {
                x.position = i;
                updateCategory(x);
            }
        })
        setCategories(nextState);
    }, [categories])

    const fetchCategories = useCallback(async () => {
        const data = await getCategories();
        data.sort((a, b) => a.position - b.position);
        setCategories(data);
    }, [])

    const openImageModal = (category) => {
        setImageModalCategory(category);
        setImageModalOpen(true);
    }

    const onCategoryImageUpdate = (cat) => {
        fetchCategories();
    }

    const submitNewCategory = useCallback(async () => {
        if (newCategory.current === "") {
            toast.warning("Please provide a name for the new category");
            return;
        } else {
            const lastPosition = Math.max(categories.map(x => x.position));
            const data = {
                name: newCategory.current,
                position: lastPosition + 1,
                folders: []
            }
            const id = await addCategory(data);
            setCategories(prev => [...prev, {...data, id: id }]);
            setNewCategoryOpen(false);
        }
    }, [categories])

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories])

    return (
        <Layout title="Images">
            <div className="categories">
                <GridContextProvider onChange={onDragEnd}>
                    <GridDropZone id="categories" boxesPerRow={window.innerWidth <= 768 ? 2 : 3} rowHeight={(window.innerHeight * 0.7) / 3} style={{ height: '80vh' }}>
                        {categories.map((cat, i) => (
                            <GridItem key={`${cat.name}`}>
                                <Category category={cat} openImageModal={openImageModal} />
                            </GridItem>
                        ))}
                    </GridDropZone>
                </GridContextProvider>
            </div>
            <ImageModal category={imageModalCategory} open={imageModalOpen} setOpen={setImageModalOpen} onUpdate={onCategoryImageUpdate} />
            <Modal className="new-category-modal" title="New Category" open={newCategoryOpen} setOpen={setNewCategoryOpen}>
                <p>Please provide a name for the new category.</p>
                <Input placeholder="Name" onChange={e => newCategory.current = e} />
                <Button title="add" onClick={submitNewCategory}>Save</Button>
            </Modal>
        </Layout>
    )
}

export default Categories;