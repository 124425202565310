import Layout from 'components/containers/layout';
import { getInvoices } from 'firestore/invoices';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { moneyFormat } from 'utils';
import './invoices.scss';
import { NewInvoiceModal } from './new-invoice-modal';

const Invoices = () => {
    const [invoices, setInvoices] = useState([]);
    const [newInvoiceOpen, setNewInvoiceOpen] = useState(false);

    const openNewInvoice = () => {
        setNewInvoiceOpen(true);
    }

    const fetchInvoices = useCallback(async () => {
        const data = await getInvoices();
        setInvoices(data);
    }, [])

    const total = useMemo(() => {
        return invoices.map(x => x.products.map(x => x.total)).flat().reduce((a, b) => a + b, 0);
    }, [invoices])

    useEffect(() => {
        fetchInvoices();
    }, [fetchInvoices])

    return (
        <Layout title="Invoices">
            <div className="invoices">
                <button onClick={openNewInvoice}>New Invoice</button>
                <div className="month-total">
                    <p>Month: &nbsp; {moneyFormat(total)}</p>
                </div>
                <div className="table-container">
                    <table className="invoice-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Recipient</th>
                                <th className="products">Products</th>
                                <th>Total</th>
                                <th>Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.map(invoice => (
                                <tr key={invoice.invoice_number}>
                                    <td>{invoice.invoice_number}</td>
                                    <td>{invoice.recipient}</td>
                                    <td>{invoice.products.length}</td>
                                    <td>{moneyFormat(invoice.products.map(x => x.total).reduce((a, b) => a + b, 0))}</td>
                                    <td>{moment(new Date(invoice.date)).format("D-MM-YYYY")}</td>
                                    <td><a href={invoice.url} target="_blank" rel="noreferrer" title="view invoice">View</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <NewInvoiceModal open={newInvoiceOpen} setOpen={setNewInvoiceOpen} />
            </div>
        </Layout>
    )
}

export default Invoices;