import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "views/dashboard";
import Images from "views/images";
import Invoices from "views/invoices";
import Products from "views/products";
import Login from "./views/login";
import Categories from "./views/categories";
import Reviews from "views/reviews";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/products" element={<Products />} />
                <Route path="/images/:category" element={<Images />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/images" element={<Categories />} />
                <Route path="/reviews" element={<Reviews />} />
            </Routes>
        </BrowserRouter>
    );
}
  
export default App;
  