import './category.scss';

import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useRef, useState } from 'react';
import { deleteCategory, updateCategory } from 'firestore/categories';
import { useNavigate } from 'react-router-dom';

const Category = ({ category, openImageModal }) => {
    const navigate = useNavigate();

    const ref = useRef();
    const [open, setOpen] = useState(false);

    const onClick = (e, name) => {
        navigate(`/images/${name}`);
    }

    const onBlur = () => {
        setOpen(false);
    }

    const onInputBlur = () => {
        updateCategory({
            ...category,
            name: ref.current.value
        })
    }

    const onInputFocus = e => {
        e.stopPropagation();
    }

    const deleteConfirm = async e => {
        e.stopPropagation();
        if (window.confirm('Are you sure you want to delete this folder? All images inside will be deleted as well.')) {
            await deleteCategory(category.id);
        }
    }

    const onIconClick = e => {
        e.stopPropagation();
        setOpen(!open);
    }

    const handleOpenImageModal = e => {
        e.stopPropagation();
        setOpen(false);
        openImageModal(category);
    } 

    return (
        <div className={`category-container`} onDoubleClick={e => onClick(e, category.name.toLowerCase())}>
            <div className="category" style={{ backgroundImage: `url(${category.image_url})`}} tabIndex={-1} onBlur={onBlur}>
                {open ? <CloseOutlinedIcon onClick={onIconClick} /> : <MoreHorizOutlinedIcon onClick={onIconClick} />}
                <input defaultValue={category?.name} placeholder="Name" type="text" onClick={onInputFocus} onBlur={onInputBlur} ref={ref} />
                <ul className={`category-dropdown${open ? ' open' : ''}`}>
                    <li onClick={handleOpenImageModal}>Change Image</li>
                    <li onClick={deleteConfirm}>Delete</li>
                </ul>
            </div>
        </div>
    )
}

export default Category;