import { addDoc, collection, getDocs, query, where } from "firebase/firestore/lite";
import { db } from "firestore";
import { handleError } from "utils";

export const uploadInvoice = async (invoice) => {
    try {
        const col = collection(db, 'invoices');
        const ref = await addDoc(col, invoice);
        return ref.id;
    } catch (error) {
        handleError(error);
    }
}

export const getInvoices = async () => {
    try {
        const col = collection(db, 'invoices');
        const snapshot = await getDocs(col);
        const response = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}));
        return response;
    } catch (error) {
        handleError(error);
    }
}

export const checkInvoiceNumber = async (num) => {
    const q = query(collection(db, "invoices"), where("invoice_number", "==", num));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        return false;
    } else {
        return true;
    }
}