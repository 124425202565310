import './images.scss';

import Layout from 'components/containers/layout';

const Images = () => {
    return (
        <Layout title="Images">
            <div className="images">
                
            </div>
        </Layout>
    )
}

export default Images;