import { useRef } from 'react';
import './input.scss';

const Input = ({type, placeholder, onChange, defaultValue, icon, editable, reff}) => {
    const value = useRef(defaultValue);

    const handleOnChange = (e) => {
        value.current = e.currentTarget.value;
        onChange && onChange(e.currentTarget.value);
    }

    return (
        <div className="input" tabIndex={-1}>
            <input type={type} onChange={handleOnChange} defaultValue={defaultValue} placeholder={placeholder} disabled={editable} ref={reff} />
            {icon && <div className="icon">{icon}</div>}
        </div>
    )
}

export default Input;