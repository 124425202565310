import { Link, useLocation } from 'react-router-dom';
import './header.scss';

const Header = ({ title }) => {
    const location = useLocation();
    const paths = location?.pathname?.split('/').filter(x => x !== "");

    return (
        <div className="header">
            <h1>{title}</h1>
            <div className="breadcrumbs">
                {paths.map((path, i) => (
                    <div className="breadcrumb" key={i}>
                        <Link to={`/${paths?.slice(0, i + 1).join()}`}>{path}</Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Header;